.feedback-container {
  height: 460px;
  width: 100%;
}

#comment {
  width: 426px;
  height: 104px;
  background-color: #ffffff;
}

.comment {
  margin-top: 30px;
}

.k-dialog-titlebar {
  color: #222222 !important;
}

.dropzone {
  width: 390px;
  height: 110px;
  border: 1px dashed #aaaaaa;
  display: flex;
  flex-direction: column;
  align-content: stretch;
  align-items: center;
  padding: 20px;
  line-height: 0px;
}

.attachment-button {
  border-color: #999999 !important;
}

textarea {
  min-height: 100px;
  height: inherit !important;
  width: 100%;
  background: #ffffff !important;
  border: 1px solid #bbbbbb;
  border-radius: 2px;
  box-sizing: border-box;
  display: flex !important;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 8px;
  outline: none !important;
  font-family: "Segoe UI" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 13px !important;
  line-height: 20px;
  color: #666666;
}

.feedbackLabel {
  font-family: Segoe UI!important;
  font-size: 13px !important;
  font-weight: 600 !important;
  letter-spacing: 0px;
  text-align: left;
}
