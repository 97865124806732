.wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 65px;
  right: 0;
  bottom: 0;
  top: 0;
}

.header {
  background: #fff;
  height: 60px;
  margin-left: 10px;
  order: 1;
}

.breadcrumb {
  background: #fff;
  height: 50px;
  padding: 0px 22px;
  order: 2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.container {
  background-color: #F6F6F6;
  display: flex;
  flex-direction: column;
  overflow: auto;
  order: 3;
  flex: 3;
}

.popout-container {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.divider {
  border-top: 1px solid #dddddd;
  order: 2;
}

.footer {
  height: 51px;
  background-color: white;
  order: 4;
}