.cardCustomContent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.cardIconContainer>svg {
  width: 20px;
  height: 22px;
  padding: 10px;
}

.cardIconContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  width: 40px;
  height: 40px;
  background: #F5F5F5;
  border-radius: 48px;
}

.cardInfoContainer {
  display: flex;
  flex-direction: column;
  width: 232px;
  padding: 8px 16px;
  gap: 4px;
}

.pageName {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #00729E;
  font-style: normal;
  word-break: break-word;
}

.lastOpened {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #222222;
  font-style: normal;
}