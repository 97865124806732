.about-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.union-products-container {
  margin-bottom: 3rem;
}

.item {
  width: calc(100% / 3);
  padding: 5px 0px;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.first-item {
  margin-left: 0;
}

.row {
  display: flex;
  flex-direction: row;
  padding-bottom: 5px;
}

table {
  width: 100%;
}

th {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #222222;
}

td {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
}
